.container {
    margin: 10pt;
}

.topContainer {
    padding: 4vmin;
    padding-left: 4vmin;
    padding-right: 4vmin;
    border-bottom: 1px solid #ddd;
}

.cityName {
    margin-left: 2vw;
    margin-right: 2vw;
}

.box-shadow {
    border-radius: 4vmin;
    box-shadow: 1px 1px 6px #ddd;
    border-width: 1px;
    border-style: solid;
    border-color: #ddd;
    padding: 10;
}